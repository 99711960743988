import { isMobile } from 'react-device-detect';
import { useMedia } from 'react-use';

import { breakpoints } from '@/app.config';

import { useHover } from '@/hooks/useHover';

import { useNetwork } from '@/contexts/NetworkContext';

export const useCardHover = <ElementRef extends HTMLElement>() => {
  const { isTasix } = useNetwork();

  const { ref, isHovered } = useHover<ElementRef>();
  const isMedium = useMedia(breakpoints.medium, true);

  const isCardHovered = Boolean(!isMobile && isMedium && isHovered && isTasix);
  return { ref, isHovered: isCardHovered };
};
