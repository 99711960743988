'use client';

import { useContext, createContext, useState } from 'react';
import { useEffectOnce } from 'react-use';

import { ChildrenProps } from '@/types/common.types';

import { httpClient } from '@/libs/http-client';

// =================================================================

type NetworkState = {
  isTasix: boolean;
  ipAddress: string;
};

const NetworkContext = createContext<NetworkState | null>(null);

// =================================================================

export const NetworkProvider = (props: ChildrenProps) => {
  const { children } = props;

  const [isTasix, setIsTasix] = useState(false);
  const [ipAddress, setIpAddress] = useState('');

  useEffectOnce(() => {
    const verifyNetwork = async () => {
      const {
        data: { tasix, ip },
      } = await httpClient.get<{ tasix: boolean; ip: string }>('/network/tas-ix');

      setIsTasix(tasix);
      setIpAddress(ip);
    };

    verifyNetwork();
  });

  return (
    <NetworkContext.Provider value={{ isTasix, ipAddress }}>{children}</NetworkContext.Provider>
  );
};

// =================================================================

export const useNetwork = () => {
  const context = useContext(NetworkContext);

  if (!context) {
    throw new Error('useNetwork should be used within NetworkProvider');
  }

  return context;
};
