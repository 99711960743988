import { type ForwardedRef, forwardRef } from 'react';

import { ForwardedLink } from '../ForwardedLink';
import clsx from 'clsx';

import styles from '@/components/Card/CardImage.module.scss';
import { Image, type ImageProps } from '@/components/Image';

// =================================================================

type CardImageCommonProps = Pick<
  ImageProps,
  'aspectRatio' | 'children' | 'className' | 'src' | 'sizes'
> & {
  alt?: string;
  onClick?: () => void;
  imageClassName?: string;
  imageWrapperClassName?: string;
};

type CardImageProps = CardImageCommonProps &
  ({ as: 'div'; href?: never } | { as: 'link'; href: string } | { as: 'button'; href?: never });

// =================================================================

export const CardImage = forwardRef<
  HTMLDivElement | HTMLAnchorElement | HTMLButtonElement,
  CardImageProps
>((props, ref) => {
  const {
    aspectRatio,
    children,
    src,
    alt = 'poster',
    className,
    sizes,
    onClick,
    imageClassName,
    imageWrapperClassName,
  } = props;

  const childElement = (
    <Image
      src={src}
      alt={alt}
      aspectRatio={aspectRatio}
      className={imageClassName}
      wrapperClassName={imageWrapperClassName}
      fill
      sizes={sizes}
    >
      {children}
    </Image>
  );

  if (props.as === 'link') {
    return (
      <ForwardedLink
        ref={ref as ForwardedRef<HTMLAnchorElement>}
        href={props.href}
        className={clsx(styles.cardImageWrapper, className)}
        onClick={onClick}
      >
        {childElement}
      </ForwardedLink>
    );
  }

  if (props.as === 'button') {
    return (
      <button
        ref={ref as ForwardedRef<HTMLButtonElement>}
        className={clsx(styles.cardImageWrapper, className)}
        onClick={onClick}
      >
        {childElement}
      </button>
    );
  }

  return (
    <div
      ref={ref as ForwardedRef<HTMLDivElement>}
      className={clsx(styles.cardImageWrapper, className)}
      onClick={onClick}
    >
      {childElement}
    </div>
  );
});

// =================================================================

if (process.env.NODE_ENV !== 'production') {
  CardImage.displayName = 'CardImage';
}
