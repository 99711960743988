import { type ForwardedRef, forwardRef } from 'react';

import clsx from 'clsx';

import { ChildrenProps, ClassNameProps } from '@/types/common.types';

import styles from '@/components/Card/Card.module.scss';
import { type ForwardedLinkProps, ForwardedLink } from '@/components/ForwardedLink';

// =================================================================

interface CardProps extends ChildrenProps, ClassNameProps {
  onClick?: () => void;
}

// =================================================================

export const Card = forwardRef<HTMLDivElement, CardProps>((props, ref) => {
  const { children, className, onClick } = props;

  return (
    <div
      ref={ref}
      // href={props.href!}
      onClick={onClick}
      className={clsx(styles.card, className)}
    >
      {children}
    </div>
  );
});

// =================================================================

if (process.env.NODE_ENV !== 'production') {
  Card.displayName = 'Card';
}
