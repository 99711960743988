import type { ReactNode, ElementType } from 'react';

import clsx from 'clsx';

import styles from '@/components/SectionHeading/SectionHeading.module.scss';

// =================================================================

interface SectionHeadingProps {
  as?: ElementType;
  variant?: 'gradient';
  children: ReactNode;
  className?: string;
}

// =================================================================

export const SectionHeading = (props: SectionHeadingProps) => {
  const { variant = 'gradient', as: Element = 'h2', children, className } = props;

  return (
    <Element className={clsx(styles.sectionHeading, styles[variant], className)}>
      {children}
    </Element>
  );
};
