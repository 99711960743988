import { AxiosResponse } from 'axios';
import md5 from 'blueimp-md5';
import queryString from 'query-string';

import { VideoFeed } from '@/app/actions/movie/types';
import { Locale } from '@/types/common.types';

import { InfiniteLoaderData, PaginationParams } from '@/api/types/common.types';
import { QueryFilterParams } from '@/api/types/filters.types';
import { GenresResponse, WebGenres } from '@/api/types/home.types';
import type {
  MovieList,
  Movie,
  MovieListResponse,
  MoviesFilterListByGenre,
  MoviesFilterListByYear,
  CommentListResponse,
  Comment,
  RepliesCommentListResponse,
  SerialSeason,
  SerialFiles,
  MovieFile,
  PersonRole,
  MoviesFilterListByPerson,
  ToggleFavoriteResponse,
  Snapshot,
} from '@/api/types/movie.types';

import { httpClient } from '@/libs/http-client';

export class MovieApi {
  static extractMoviesData = (movies: MovieList[] = []): Movie[] => {
    return movies.map(movie => ({
      id: movie.id,
      title: movie.title,
      title_en: movie.titleEn,
      genres: movie.genres,
      countries: movie.countries,
      age_limit: movie.ageLimit,
      card_type: movie.cardType,
      is_free: movie.isFree,
      is_new: movie.isNew,
      is_serial: movie.isSerial,
      module_id: movie.moduleId,
      poster: movie.poster,
      quality: movie.quality,
      year: movie.year,
      is_favourite: movie.isFavourite,
      module_slug: movie.moduleSlug,
      rating: movie?.rating,
      is_download: false,
    }));
  };

  // =================================================================

  static fetchMovieList = async (
    params: QueryFilterParams & {
      module_id: number | string;
      page: number;
      items?: number;
    },
  ) => {
    const { module_id, page, items = 18, countries, genres, qualities, yearFrom, yearTo } = params;

    const queryParams = queryString.stringify(
      {
        module_id,
        page,
        items,
        'filter[genre][]': genres,
        'filter[country][]': countries,
        'filter[start_year]': yearFrom,
        'filter[end_year]': yearTo,
        'filter[quality][]': qualities,
      },
      {
        encode: false,
      },
    );

    const { data } = await httpClient.get<MovieListResponse>(`/content/movies/list?${queryParams}`);

    const transformedData: InfiniteLoaderData<MovieList> = {
      currentPage: data.currentPage,
      lastPage: data.lastPage,
      total: data.total,
      list: data.movies,
    };

    return transformedData;
  };

  // =================================================================

  static fetchMovieListByGenre = async (params: {
    genreId: string;
    page: number;
    items?: number;
  }) => {
    const { genreId, page, items = 30 } = params;

    const { data } = await httpClient<MoviesFilterListByGenre>('/content/genre', {
      params: {
        id: genreId,
        page,
        items,
      },
    });

    const transformedData: InfiniteLoaderData<MovieList> = {
      lastPage: data.moviesData.lastPage,
      currentPage: data.moviesData.currentPage,
      total: data.moviesData.total,
      list: data.moviesData.movies,
      extra: { title: data.info.title },
    };

    return transformedData;
  };

  // =================================================================

  static fetchMovieListByCountry = async (params: {
    countryId: string;
    page: number;
    items?: number;
  }) => {
    const { countryId, page, items = 30 } = params;

    const { data } = await httpClient<MoviesFilterListByGenre>('/content/country', {
      params: {
        id: countryId,
        page,
        items,
        module_id: 3,
      },
    });

    const transformedData: InfiniteLoaderData<MovieList> = {
      lastPage: data.moviesData.lastPage,
      currentPage: data.moviesData.currentPage,
      total: data.moviesData.total,
      list: data.moviesData.movies,
      extra: { title: data.info.title },
    };

    return transformedData;
  };

  // =================================================================

  static fetchMovieListByYear = async (params: {
    yearId: string;
    page: number;
    items?: number;
  }) => {
    const { yearId, page, items = 30 } = params;

    const { data } = await httpClient<MoviesFilterListByYear>('/content/year', {
      params: {
        year: yearId,
        page,
        items,
        module_id: 3,
      },
    });

    const transformedData: InfiniteLoaderData<MovieList, { title: string }> = {
      lastPage: data.moviesData.lastPage,
      currentPage: data.moviesData.currentPage,
      total: data.moviesData.total,
      list: data.moviesData.movies,
      extra: { title: data.year },
    };

    return transformedData;
  };

  // =================================================================

  static fetchMovieListByPerson = async (params: {
    personId: string;
    roleId: string | null;
    page: number;
    items?: number;
  }) => {
    const { personId, roleId, page, items = 30 } = params;

    const { data } = await httpClient<MoviesFilterListByPerson>('/content/actor', {
      params: {
        module_id: 3,
        id: personId,
        role: roleId,
        page,
        items,
      },
    });

    const transformedData: InfiniteLoaderData<MovieList> = {
      lastPage: data.moviesData.lastPage,
      currentPage: data.moviesData.currentPage,
      total: data.moviesData.total,
      list: data.moviesData.movies,
      extra: { person: data.info, roles: data.roles },
    };

    return transformedData;
  };

  // =================================================================

  static fetchSearchedByQuery = async (
    params: Partial<Pick<PaginationParams, 'items' | 'page'>> & { query: string },
  ) => {
    const { items, page, query } = params;
    const queryParams = queryString.stringify({ q: query, items, page });

    const { data } = await httpClient.get<
      Omit<MovieListResponse, 'movies'> & { movies: MovieList[] }
    >(`/search?${queryParams}`);

    const transformedData: InfiniteLoaderData<MovieList> = {
      currentPage: data.currentPage,
      lastPage: data.lastPage,
      total: data.total,
      list: data.movies,
    };

    return transformedData;
  };

  // =================================================================

  static fetchReplies = async (params: {
    moduleId: string;
    movieId: string;
    commentId: string;
    page: number | string;
    items: number;
  }) => {
    const { commentId, items, moduleId, movieId, page } = params;

    const queryParams = queryString.stringify({
      id: movieId,
      module_id: moduleId,
      comment_id: commentId,
      items,
      page,
    });

    const { data } = await httpClient.get<RepliesCommentListResponse>(
      `/content/movie/comment/replies?${queryParams}`,
    );

    const transformedData: InfiniteLoaderData<Comment> = {
      currentPage: data.currentPage,
      lastPage: data.lastPage,
      total: data.total,
      list: data.replies,
    };

    return transformedData;
  };

  // =================================================================

  static fetchComments = async (params: { movieId?: string; items?: number; page: number }) => {
    const { items, movieId, page } = params;

    const queryParams = queryString.stringify({ id: movieId, items, page });

    const { data } = await httpClient.get<CommentListResponse>(
      `/content/movie/comment/list?${queryParams}`,
    );

    return data;
  };

  // =================================================================

  static fetchFilesIdsBySeasonId = async (params: {
    moduleId: string;
    movieId: string;
    seasonId: string;
  }) => {
    const { moduleId, movieId, seasonId } = params;
    const query = queryString.stringify({
      module_id: moduleId,
      id: movieId,
      season_id: seasonId,
    });
    const { data } = await httpClient<number[]>(`/content/movie/season-file-ids?${query}`);
    return data;
  };

  // =================================================================

  static fetchSerialSeasons = async (params: {
    moduleId: string;
    movieId: string;
    locale: Locale;
  }) => {
    const { moduleId, movieId, locale } = params;

    const { data: seasons } = await httpClient<SerialSeason[]>(
      `/content/movie/seasons?module_id=${moduleId}&id=${movieId}&sort=asc`,
      {
        headers: {
          Locale: locale,
        },
      },
    );

    return seasons;
  };

  // =================================================================

  static fetchSerialFiles = async (params: {
    moduleId: string;
    movieId: string;
    seasonId: string;
    page: number;
    locale: Locale;
  }) => {
    const { moduleId, movieId, page, seasonId, locale } = params;

    const {
      data: { files, lastPage },
    } = await httpClient<SerialFiles>(
      `/content/movie/series-files?module_id=${moduleId}&id=${movieId}&sort=asc&season_id=${seasonId}&items=10&page=${page}`,
      {
        headers: {
          Locale: locale,
        },
      },
    );

    return { files, lastPage };
  };

  // =================================================================

  static fetchMovieFiles = async (params: { moduleId: string; movieId: string }) => {
    const { moduleId, movieId } = params;

    const { data } = await httpClient<MovieFile[]>(
      `/content/movie/movie-files?module_id=${moduleId}&id=${movieId}`,
    );

    return data;
  };

  // =================================================================

  static fetchDownloadLink = async (params: {
    moduleId: string;
    fileId: string;
    token: string;
  }) => {
    const { moduleId, fileId, token } = params;

    const hash = md5(`${fileId}${token}${process.env.NEXT_PUBLIC_CINERAMA_HASH_SECRET}`);
    const query = queryString.stringify({
      module_id: moduleId,
      id: fileId,
      hash,
    });

    const { data } = await httpClient<{ link: string }>(`/content/movie/file/download?${query}`);

    return data;
  };

  // =================================================================

  static saveLastPosition = (params: {
    movie_id: number;
    file_id: number;
    is_watched?: boolean;
    last_position?: number;
    locale?: string;
  }) => {
    const { file_id, movie_id, is_watched, last_position, locale } = params;

    const query = queryString.stringify({
      file_id,
      movie_id,
      last_position,
      is_watched: Number(is_watched).toString(),
      locale,
    });

    return httpClient(`/content/movie/file/last-position?${query}`);
  };

  // =================================================================

  static fetchPersonRoles = async (personId: string) => {
    const { data } = await httpClient.get<PersonRole[]>('/content/actor/roles', {
      params: {
        id: personId,
      },
    });

    return data;
  };

  // =================================================================

  static addComment = ({
    movieId,
    userId,
    commentText,
  }: {
    movieId: number;
    userId: number;
    commentText: string;
  }) => {
    const data = { movie_id: movieId, author_id: userId, message: commentText };
    return httpClient.post<unknown, AxiosResponse & { code?: number }>(
      '/content/movie/comment/send',
      data,
    );
  };

  // =================================================================

  static addReply = async ({
    movieId,
    userId,
    commentId,
    reply,
  }: {
    movieId: number;
    userId: number;
    commentId: number;
    reply: string;
  }) => {
    const data = {
      movie_id: movieId,
      author_id: userId,
      comment_id: commentId,
      message: reply,
    };

    const response = await httpClient.post<unknown, AxiosResponse & { code?: number }>(
      '/content/movie/comment/reply',
      data,
    );

    return response;
  };

  // =================================================================

  static toggleLike = ({
    movieId,
    commentId,
    userId,
  }: {
    movieId: number;
    commentId: number;
    userId: number;
  }) => {
    const params = queryString.stringify({
      movie_id: movieId,
      comment_id: commentId,
      abonent_id: userId,
    });
    return httpClient.get<unknown, AxiosResponse & { code?: number }>(
      `/content/movie/comment/like?${params}`,
    );
  };

  // =================================================================

  static toggleDislike = ({
    movieId,
    commentId,
    userId,
  }: {
    movieId: number;
    commentId: number;
    userId: number;
  }) => {
    const params = queryString.stringify({
      movie_id: movieId,
      comment_id: commentId,
      abonent_id: userId,
    });
    return httpClient.get<unknown, AxiosResponse & { code?: number }>(
      `/content/movie/comment/dislike?${params}`,
    );
  };

  // =================================================================

  static toggleFavorite = ({ moduleId, movieId }: { moduleId: number; movieId: number }) => {
    const params = queryString.stringify({
      module_id: moduleId,
      movie_id: movieId,
    });
    return httpClient.get<ToggleFavoriteResponse>(`/content/movie/favourite/toggle?${params}`);
  };

  // =================================================================

  static fetchVideoFeed = async (fileId: number | string, token: string, type: string = 'hls') => {
    const hash = md5(`${fileId}${type}${token}${process.env.NEXT_PUBLIC_CINERAMA_HASH_SECRET}`);
    const params = queryString.stringify({ id: fileId, type, hash });
    const response = await httpClient.get<VideoFeed, AxiosResponse & { code?: number }>(
      `/content/movie/file/play?${params}`,
    );

    return response;
  };

  // =================================================================

  static fetchMovieSnapshots = async (params: { module_id: string; id: string }) => {
    const { data } = await httpClient.get<Snapshot[]>('/content/movie/snapshots', { params });

    return data;
  };

  // =================================================================

  static fetchRelatedMovies = async (params: { module_id: string; id: string }) => {
    const { data } = await httpClient.get<MovieList[]>('/content/movie/related', { params });

    return data;
  };

  // =================================================================

  static fetchGenres = async (params: PaginationParams) => {
    const { data } = await httpClient.get<GenresResponse>('/genre', {
      baseURL: process.env.NEXT_PUBLIC_CINERAMA_API_BASE_VERSION_3,
      params,
    });

    const transformedData: InfiniteLoaderData<WebGenres> = {
      currentPage: data.current_page,
      lastPage: data.last_page,
      total: data.total,
      list: data.list,
    };

    return transformedData;
  };
}
