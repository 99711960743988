import { JSX } from 'react';

import clsx from 'clsx';

import { ChildrenProps, ClassNameProps } from '@/types/common.types';

import styles from '@/components/Card/CardOverlay.module.scss';

interface CardOverlayProps extends Partial<ChildrenProps>, ClassNameProps {
  icon?: (props: ClassNameProps) => JSX.Element;
  iconClassName?: string;
}

export const CardOverlay = (props: CardOverlayProps) => {
  const { children, className } = props;

  return <div className={clsx(styles.cardOverlay, className)}>{children}</div>;
};
