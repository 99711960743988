import { memo, type ElementType } from 'react';

import clsx from 'clsx';
import NextImage, { type ImageProps as NextImageProps } from 'next/image';

import { ChildrenProps, ClassNameProps } from '@/types/common.types';

import styles from '@/components/Image/Image.module.scss';

export interface ImageProps extends ClassNameProps, Partial<ChildrenProps>, NextImageProps {
  as?: ElementType;
  title?: string;
  wrapperClassName?: string;
  aspectRatio: number;
}

// =================================================================

export const Image = memo((props: ImageProps) => {
  const {
    as: Element = 'div',
    title,
    aspectRatio,
    children,
    className,
    wrapperClassName,
    ...rest
  } = props;

  return (
    <Element
      title={title}
      style={{ paddingBottom: `${100 / aspectRatio}%` }}
      className={clsx(styles.imageWrapper, wrapperClassName)}
    >
      <NextImage {...rest} className={clsx(styles.image, className)} />
      {children && <div className={styles.cardSlot}>{children}</div>}
    </Element>
  );
});

// =================================================================

if (process.env.NODE_ENV !== 'production') {
  Image.displayName = 'Image';
}
