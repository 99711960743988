import clsx from 'clsx';

import styles from '@/components/Card/CardSubtitle.module.scss';
import { TruncatedText, type TruncatedTextProps } from '@/components/TruncatedText';

export const CardSubtitle = (props: Omit<TruncatedTextProps, 'lines'>) => {
  const { children, className } = props;

  if (props.as === 'link') {
    return (
      <TruncatedText as={'link'} href={props.href!} className={clsx(styles.subtitle, className)}>
        {children}
      </TruncatedText>
    );
  }

  return (
    <TruncatedText as={props.as} className={clsx(styles.subtitle, className)}>
      {children}
    </TruncatedText>
  );
};
